import React, {useState, useEffect, useContext} from "react";
import {AppContext} from "./../../AppContext";
const styles = {
  strokeLinecap: "square",
};

const backgroundBar = "#950901";
const backgroundClock = "#D9D9D9";

function ClockSvg({isHeaderIcon}) {
  const [percentaje, setPercentaje] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const {activationDate, codeExpires} = useContext(AppContext);

  const setPercentajeClock = () => {
    // const timeLeft = codeTimeLeft - seconds * 1000;
    // setTimeLeft(timeLeft);
    // const consumedTime = codeTotalHours * 3600 * 1000 - timeLeft;
    // const percentaje = Math.trunc(
    //   (consumedTime * 100) / (codeTotalHours * 1000 * 3600)
    // );
    // if (percentaje % 5 === 0) setPercentaje(percentaje);
    // else {
    //   setPercentaje(percentaje - (percentaje % 5));
    // }

    const consumedTime = 24 * 3600 * 1000 - timeLeft * 3600 * 1000;
    const percentaje = Math.trunc((consumedTime * 100) / (24 * 1000 * 3600));
    if (percentaje % 5 === 0) setPercentaje(percentaje);
    else {
      setPercentaje(percentaje - (percentaje % 5));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft =
        new Date(activationDate).getTime() +
        codeExpires * 60 * 1000 -
        new Date().getTime();
      if (timeLeft <= 0) {
        console.log("OUT USER");
      }
      setTimeLeft(timeLeft / 1000 / 3600);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (timeLeft > 0) setPercentajeClock();
  }, [timeLeft]);

  return (
    <svg
      viewBox="0 0 36 36"
      width={isHeaderIcon ? 50 : 80}
      height={isHeaderIcon ? 50 : 80}
    >
      <path
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke="#D9D9D9"
        strokeWidth="3"
      />
      <path
        style={{...styles, strokeDasharray: `${percentaje} 100`}}
        d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke={percentaje < 1 ? backgroundClock : backgroundBar}
        strokeWidth="3"
      />
    </svg>
  );
}

export default ClockSvg;
