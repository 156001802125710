import React, {useEffect, useState} from "react";
import {useContext} from "react";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {Navbar} from "react-bootstrap";
import styled from "styled-components";
import ClockLoader from "react-spinners/ClockLoader";
import TimerIcon from "../../assets/svg/timer.svg";
import LOGO from "./../../assets/logo1.svg";
import ClockSvg from "../SVGS/ClockSvg";
import BACK from "./../../assets/svg/flecha_back1.svg";
import BACKPRESS from "./../../assets/svg/flecha_back2.svg";
import {AppContext} from "./../../AppContext";
import AudioIconSvg from "../../assets/svg/audio.svg";
import "./index.css";

import {set} from "react-ga";
import {activateCode} from "../../utils/Api";

const NavbarWrapper = styled(Navbar)`
  height: 107px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px #f0f1f2;
  cursor: pointer;
  justify-content: center;
`;
const Image = styled.img`
  width: 152px;
`;
const DivBack = styled.div`
  position: absolute;
  position: fixed;
  width: 30px;
  height: 30px;
  left: 20px;
  top: 38.5px;
  cursor: pointer;
  background-image: url("${BACK}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  cursor: pointer;
  &:focus,
  &:hover {
    background-image: url("${BACKPRESS}");
  }
`;

const DivTimer = styled.div`
  position: absolute;
  right: 0;
  top: 28.5px;
  padding: 0 10px;
  font-size: 22px;
`;

const DivTimerImg = styled.img`
  margin-right: 5px;
`;
const AudioIconImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const clockIconStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const Header = () => {
  let history = useHistory();
  let location = useLocation();
  //let {collectionId} = useParams();
  const [collectionId, setCollectionId] = useState(0);
  let match = useRouteMatch("/collection/:collectionId");

  const {isActiveSocket} = useContext(AppContext);

  const goToHome = () => {
    if (!isActiveSocket) return;
    history.push("/languages");
    //history.push("/start");
  };

  const goBack = () => {
    history.push(`/collection/${collectionId}`);
  };

  const hideMenu = () => {
    return (
      location.pathname.includes("collection") &&
      location.pathname.includes("artwork")
    );
  };

  useEffect(() => {
    if (match && match.params && match.params.collectionId)
      setCollectionId(match.params.collectionId);
    //console.log("MATHC", location, match.params.collectionId);
  }, [location, match]);

  return (
    <React.Fragment>
      <NavbarWrapper fixed="top" className="">
        <Navbar.Brand>
          <Image onClick={goToHome} src={LOGO} alt="Logo App" />
          {hideMenu() && <DivBack onClick={goBack} />}
        </Navbar.Brand>{" "}
        {/* {isActiveSocket && (
          
            <DivTimerImg src={TimerIcon} alt="" width="20"></DivTimerImg>{" "}
            <Timer initialTime={codeTimeLeft}></Timer>
          </DivTimer>
        )} */}
        {isActiveSocket && (
          <DivTimer>
            <AudioIconImg src={AudioIconSvg} alt="" width="25" />
            {/* <ClockLoader
              size={50}
              color={"#D9D9D9"}
              loading={true}
              css={clockIconStyle}
            /> */}
            <ClockSvg isHeaderIcon={true}></ClockSvg>
          </DivTimer>
        )}
      </NavbarWrapper>
    </React.Fragment>
  );
};

export default Header;
