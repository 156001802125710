import {reactLocalStorage} from "reactjs-localstorage";

import FingerprintJS from "@fingerprintjs/fingerprintjs";

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();

const headers = {
  "Content-Type": "application/json",
  ...(process.env.REACT_APP_API_KEY && {
    "x-api-key": process.env.REACT_APP_API_KEY,
  }),
};

export const verifyCode = async (param) => {
  console.log(process.env.REACT_APP_SERVER_URL);
  try {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/verifyCode`,

      {
        method: "POST",
        headers,
        body: JSON.stringify({token: param}),
      }
    );
    const data = await res.json();
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const validateCode = async (code) => {
  try {
    const fp = await fpPromise;
    const {visitorId} = await fp.get();
    const storageDevice =
      reactLocalStorage.get("device_id") === "undefined"
        ? ""
        : reactLocalStorage.get("device_id");

    console.log(storageDevice);
    console.log(visitorId);
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/validateCode`,
      {
        method: "POST",
        headers,
        body: JSON.stringify({
          device_id: storageDevice ? storageDevice : visitorId,
          id: code,
        }),
      }
    );
    const data = await res.json();

    if (data.error) {
      throw new Error(data.error);
    }
    reactLocalStorage.set("device_id", data.data.device_id);
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
};

export const getCode = async (code) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/getCode/${code}`,
      {
        headers,
      }
    );
    const data = await res.json();

    if (data.error) {
      throw new Error(data.error);
    }
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const activateCode = async (code) => {
  try {
    const fp = await fpPromise;
    const {visitorId} = await fp.get();

    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/activateCode/${code}`,
      {
        method: "PATCH",
        headers,
        body: JSON.stringify({device_id: visitorId}),
      }
    );
    const data = await res.json();
    reactLocalStorage.set("device_id", data.data.device_id);
    if (data.error) {
      throw new Error(data.error);
    }
    return data.data;
  } catch (err) {
    throw new Error(err.message);
  }
};
