import React, { useState, useEffect, memo } from "react";
import { Col } from "react-bootstrap";

const Player = memo(({ url }) => {
      return (
    <audio src={url} controls controlsList="nodownload">
      Error: your web browser does not support this audio player.
    </audio>
      );
});

const Audio = memo(({ url }) => {
  const [ra, setRa] = useState(Math.floor(Math.random() * 11));
  useEffect(() => {
    setRa(getUUID());
  }, [url]);

  const getUUID = () => {
    const milis = new Date().valueOf();
    const random = Math.floor(Math.random() * 11);
    return `${milis}${random}`
  }
  return (
    <React.Fragment>
      <Col
        key={`audio-${ra}`}
        className="col-12 mb-3 d-flex justify-content-center "
      >
        <Player url={url} />
      </Col>
    </React.Fragment>
  );
});

export default Audio;
