import React, { useEffect, useState, memo } from "react";
import { Row, Col } from "react-bootstrap";
import Videojs from "./../VideoJs";
import "./../../../node_modules/video-react/dist/video-react.css";

const VideoPlayer = memo(({ url, name }) => {
  const videoJsOptions = {
    autoplay: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    width: 370,
    height: 160,
    controls: true,
    sources: [
      {
        src: url,
        type: "video/mp4",
      },
    ],
  };
  return <Videojs {...videoJsOptions} name={name} />;
});

const Video = memo(({ url, name }) => {
  const [ra, setRa] = useState(Math.floor(Math.random() * 11));

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setRa(getUUID());
  }, [url]);

  const getUUID = () => {
    const milis = new Date().valueOf();
    const random = Math.floor(Math.random() * 11);
    return `${milis}${random}`
  }
  return (
    <React.Fragment>
      <Row className="w-80">
        <Col key={`video-${ra}`} className="d-flex justify-content-center my-2">
          <VideoPlayer url={url} name={name} />
        </Col>
      </Row>
    </React.Fragment>
  );
});

export default Video;
