import React, {useEffect, useContext, useState} from "react";
import {useParams, useHistory} from "react-router-dom";
import {Row, Col, Container, Card} from "react-bootstrap";
import styled from "styled-components";
import ClockLoader from "react-spinners/ClockLoader";
import ReactGA from "react-ga";
import ImageLoader from "./../../components/ImageLoader";
import Header from "../../components/Header";
import Video from "../../components/Video";
import AudioUrl from "../../components/Audio/url";
import {checkData} from "../../hoc";
import {AppContext} from "../../AppContext";

import NEXTSVG from "./../../assets/svg/next1.svg";
import NEXTPRESSSVG from "./../../assets/svg/next2.svg";
import BACKSVG from "./../../assets/svg/back1.svg";
import BACKPRESSSVG from "./../../assets/svg/back2.svg";
import GEOSVG from "./../../assets/svg/geolocalizacion.svg";
import GEOPRESSSVG from "./../../assets/svg/geolocalizacion1.svg";

const ContainerWrapper = styled(Container)`
  margin-top: 130px;
  margin-bottom: 30px;
`;

const ImgBackControl = styled.div`
  height: 50px;
  cursor: pointer;

  background-image: url("${BACKSVG}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 50px;
  height: 50px;
  cursor: pointer;
  &:focus,
  &:hover {
    background-image: url("${BACKPRESSSVG}");
  }
`;

const ImgNextControl = styled.div`
  height: 50px;
  cursor: pointer;

  background-image: url("${NEXTSVG}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 50px;
  height: 50px;
  cursor: pointer;
  &:focus,
  &:hover {
    background-image: url("${NEXTPRESSSVG}");
  }
`;

const DivSpace = styled.div`
  height: 50px;
  width: 50px;
`;

const CardWrapper = styled(Card)`
  box-shadow: 8px 8px 5px #dfdedb;
  &:focus,
  &:hover {
    box-shadow: 10px 10px 5px #dfdedb;
  }
`;

const GeoImg = styled.img`
  width: 20px;
  height: 30px;
  cursor: pointer;
  margin-left: 15px;
`;

const CardTitle = styled(Card.Title)`
  font-family: Montserrat-Medium;
  color: #150f15;
  font-size: 2em;
`;
const CardText = styled(Card.Text)`
  font-family: HelveticaNeue-Regular;
  margin-bottom: 0;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: bold;
`;
const CardText2 = styled(Card.Text)`
  font-family: HelveticaNeue-Regular;
  margin-bottom: 0;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 700;
`;
const useWindowHeight = () => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return height;
};

const Artwork = () => {
  let history = useHistory();
  let {collectionId, artworkId} = useParams();
  const {collections, language, filter} = useContext(AppContext);

  const [artwork, setArtwork] = useState(null);
  const [showGeo, setShowGeo] = useState(false);
  const [artworks, setArtworks] = useState([]);
  const [height] = useState(useWindowHeight());
  const [ra, setRa] = useState(Math.floor(Math.random() * 11));

  useEffect(() => {
    fetchArtwork();
    const tempRa = getUUID();
    setRa(tempRa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, artworkId]);

  const fetchArtwork = async () => {
    if (collectionId) {
      if (filter) {
        filterCollections();
      } else {
        const dataCol = collections.filter((item) => {
          return parseInt(item.id) === parseInt(collectionId);
        });
        if (dataCol.length) {
          setArtworks(sorts(dataCol[0].obras));
          const data = dataCol[0].obras.filter((item) => {
            return parseInt(item.id) === parseInt(artworkId);
          });

          if (data.length) {
            runGA(data[0]);
            setArtwork(data[0]);
          }
        }
      }
    }
  };

  const filterCollections = () => {
    setArtworks(sorts(filter.obras));
    const art = filter.obras.filter((item) => {
      return parseInt(item.id) === parseInt(artworkId);
    });
    if (art.length) {
      setArtwork(art[0]);
    }
  };

  const sorts = (array) => {
    for (let i = 0; i < array.length - 1; i++) {
      for (let j = i + 1; j < array.length; j++) {
        if (parseInt(array[i].orden) > parseInt(array[j].orden)) {
          let aux = array[i];
          array[i] = array[j];
          array[j] = aux;
        }
      }
    }
    return array;
  };

  const prev = () => {
    for (let i = 0; i < artworks.length; i++) {
      if (artworks[i].id === artwork.id) {
        if (i + 1 < artworks.length) {
          history.push(
            `/collection/${collectionId}/artwork/${artworks[i + 1].id}`
          );
        } else {
          history.push(`/collection/${collectionId}/artwork/${artworks[0].id}`);
        }
      }
    }
  };

  const next = () => {
    for (let i = 0; i < artworks.length; i++) {
      if (artworks[i].id === artwork.id) {
        if (i - 1 >= 0) {
          history.push(
            `/collection/${collectionId}/artwork/${artworks[i - 1].id}`
          );
        } else {
          history.push(
            `/collection/${collectionId}/artwork/${
              artworks[artworks.length - 1].id
            }`
          );
        }
      }
    }
  };

  const runGA = (item) => {
    if (item && language) {
      ReactGA.event({
        category: "Atwork",
        action: `Visited -${item.titulo[language.abreviatura]}`,
        value: parseInt(artworkId),
      });
    }
  };

  const showLocation = () => {
    setShowGeo(!showGeo);
    const tempRa = getUUID();
    setRa(tempRa);
  };

  const getUUID = () => {
    const milis = new Date().valueOf();
    const random = Math.floor(Math.random() * 11);
    return `${milis}${random}`;
  };

  return (
    <React.Fragment>
      {/* <Header /> */}
      <ContainerWrapper id="artwork">
        {!artwork && (
          <Row style={{marginTop: 260}}>
            <Col className="d-flex justify-content-center">
              <ClockLoader size={100} color={"#D58A95"} loading={true} />
            </Col>
          </Row>
        )}

        {artwork && artwork.titulo[language.abreviatura] && (
          <React.Fragment>
            <Row className="sg-container d-flex justify-content-center">
              <Col className="m-2 col-12 d-flex justify-content-center">
                <CardWrapper>
                  {(!artwork.es_sala || artwork.es_sala === "0") &&
                    artwork.imagen_id && (
                      <React.Fragment key={`key-card-${ra}`}>
                        {artwork.ubicacion &&
                          artwork.ubicacion[language.abreviatura] && (
                            <ImageLoader
                              hide={!showGeo}
                              key={`image-${artwork.imagen_id}-${ra}`}
                              id={artwork.ubicacion[language.abreviatura]}
                              obra={artwork.id}
                              height={height}
                              url={artwork.ubicacionUrl}
                            />
                          )}
                        <ImageLoader
                          hide={showGeo}
                          key={`image-${artwork.imagen_id}`}
                          id={artwork.imagen_id}
                          obra={artwork.id}
                          height={height}
                          url={artwork.imageUrl}
                        />
                      </React.Fragment>
                    )}
                  <Card.Body>
                    <CardText>
                      {artwork.audioguia && (
                        <React.Fragment>
                          {language.AUDIOGUIA} {artwork.audioguia}
                        </React.Fragment>
                      )}
                      &nbsp;
                    </CardText>
                    <Row>
                      <Col className="col-12">
                        <CardTitle className="d-flex justify-content-between">
                          <span>
                            {artwork.titulo[language && language.abreviatura]}
                          </span>
                          {artwork.ubicacion &&
                            artwork.ubicacion[language.abreviatura] && (
                              <GeoImg
                                onClick={showLocation}
                                src={!showGeo ? GEOSVG : GEOPRESSSVG}
                                alt=""
                              />
                            )}
                        </CardTitle>
                        <CardText2>
                          {` ${
                            artwork.planta
                              ? `${language && language.PLANTA} ${
                                  artwork.planta
                                }`
                              : ""
                          } `}{" "}
                          {`${
                            artwork.planta && artwork.sala_id && artwork.autor
                              ? "-"
                              : ""
                          } `}
                          {` ${
                            artwork.sala_id && artwork.autor
                              ? `${language && language.SALA} ${
                                  artwork.sala_id
                                }`
                              : ""
                          }`}
                        </CardText2>
                      </Col>
                    </Row>
                    <Row className="mt-4 d-flex justify-content-center">
                      <Col className="mt-4 d-flex justify-content-between col-12 mb-4">
                        <ImgBackControl onClick={next} />
                        {!(Object.keys(artwork.audio).length > 0 && DivSpace)}

                        <ImgNextControl onClick={prev} />
                      </Col>
                    </Row>
                    {/* {Object.keys(artwork.audio).length > 0 &&
                      artwork.audio[language.abreviatura] &&
                      artwork.audioUrl && (
                        <Row className="d-flex justify-content-center mb-5">
                          <audio
                            src={artwork.audioUrl}
                            controls
                            controlsList="nodownload"
                          >
                            Error: your web browser does not support this audio
                            player.
                          </audio>
                        </Row>
                      )} */}
                    {artwork.audioUrl && Object.keys(artwork.audio).length && (
                      <Row className="d-flex justify-content-center mb-5 mt-2">
                        <AudioUrl url={artwork.audioUrl} />
                      </Row>
                    )}

                    {Object.keys(artwork.video).length > 0 && artwork.videoUrl && (
                      <Row className="d-flex justify-content-center mb-5 mt-2">
                        <Video
                          name={artwork.titulo[language.abreviatura]}
                          url={artwork.videoUrl}
                        />
                      </Row>
                    )}
                  </Card.Body>
                </CardWrapper>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </ContainerWrapper>
    </React.Fragment>
  );
};

export default checkData(Artwork);
