import React, {useState, useEffect, useContext, useRef} from "react";
import {Container, Row, Col, Button, Spinner} from "react-bootstrap";
import styled from "styled-components";
import {reactLocalStorage} from "reactjs-localstorage";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Header from "./../../components/Header";
import {AppContext} from "./../../AppContext";
import LockIcon from "../../assets/svg/lock.svg";
import ClockIcon from "../../assets/svg/clock.svg";
import {getAllData} from "./../../services";
import {checkData} from "../../hoc";
import QrScanner from "qr-scanner";
// import {
//   BrowserQRCodeReader,
//   NotFoundException,
//   ChecksumException,
//   FormatException,
// } from "@zxing/library";
//import {constant} from "async";

const AppButton = styled(Button)`
  color: #000000;
  background-color: transparent;
  font-family: Montserrat-Regular;
  letter-spacing: 1px;
  font-size: 16px;
  width: 300px;
  padding: 10px 0px;
  border-radius: 20px;
  border: 1px solid #d58a95;
  &:hover {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
`;

const ButtonEnter = styled(Button)`
  opacity: ${(props) => (props.isSelected ? "1" : "0.1")};
  color: #000000;
  background-color: transparent;
  font-family: Montserrat-Regular;
  letter-spacing: 1px;
  font-size: 16px;
  width: 300px;
  padding: 10px 0px;
  border-radius: 20px;
  border: ${(props) => (props.isSelected ? "2px" : "1px")} solid #d58a95;
  &:hover {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
`;

const ContainerW = styled(Container)`
  height: 100vh;
  padding-top: 200px;
  //   margin-top: 130px;
  //   & {
  //     @media screen and (max-width: 400px) {
  //       margin-top: 100px;
  //     }
  //   }
  // margin-top: 130px;
  // & {
  //   @media screen and (max-width: 400px) {
  //     margin-top: 100px;
  //   }
  // }
`;

const CodeNumber = styled.input`
  color: #000000;
  width: 40px;
  border: none;
  border-bottom: 2px solid black;
  background-color: transparent;
  height: 55px;
  font-size: 50px;
  text-align: center;
  padding: 0;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
  font-family: Arial; //or Helvetica, or whatever
  /* Firefox */
  &:input[type="number"] {
    -moz-appearance: textfield;
  }
  &:focus {
    outline: none;
  }
  line-height: 0;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: blue;
  }
`;

const Div = styled.div``;
const RowW = styled(Row)`
  margin: 0;
  padding: 0 20px;
`;
const P = styled.p`
  font-family: Montserrat-Regular;
  font-size: 18px;
  margin-top: 30px;
  font-weight: 600;
`;
const LineP = styled.p`
  font-family: Montserrat-Regular;
  font-size: 16px;
  color: #000000;
`;

const VideoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border: 2px solid ${(props) => (props.isSelected ? "#28a745" : "#d5d5d5")};
  background-color: #ffffff;
  position: relative;
  // &:before {
  //   content: "";
  //   position: absolute;
  //   background: #ffffff;
  //   width: calc(100% + 50px + 4px - 120px);
  //   height: calc(100% + 4px);
  //   top: -2px;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   z-index: 1;
  // }
  // &:after {
  //   content: "";
  //   position: absolute;
  //   background: #ffffff;
  //   height: calc(100% + 50px + 4px - 120px);
  //   width: calc(100% + 4px);
  //   left: -2px;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   z-index: 1;
  // }
`;

const Video = styled.video`
  z-index: 2;
  width: 70vw !important;
  height: 35vh !important;
  object-fit: cover;
`;

const Lock = () => {
  let history = useHistory();
  const {t, i18n} = useTranslation();
  const [data, setData] = useState("No result");
  const videoRef = useRef(null);
  const [qrScanner, setQrScanner] = useState(null);
  const [qrCode, setQrCode] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [showEnterButton, setShowEnterButton] = useState(false);
  const {
    language,
    languages,
    setLanguages,
    setLanguage,
    setCollections,
    setSalas,
    setPlantas,
    setSubMenus,
    setQuestionnaire,
    setDoQuestionnaire,
    setIsUserLocked,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [isTimeExpired, setIsTimeExpired] = useState(false);
  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [num3, setNum3] = useState("");
  const [num4, setNum4] = useState("");

  const ref1 = React.createRef();
  const ref2 = React.createRef();
  const ref3 = React.createRef();
  const ref4 = React.createRef();
  const send = React.createRef();

  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [code, setCode] = useState(null);
  const [videoInputDevices, setVideoInputDevices] = useState([]);

  //const codeReader = new BrowserQRCodeReader();

  /**QR READER FUNCTIONS */

  // useEffect(() => {
  //   if (!showQr) return;
  //   const videoElement = videoRef.current;
  //   const scanner = new QrScanner(videoElement, (result) => {
  //     setQrCode(result);
  //   });

  //   setQrScanner(scanner);
  //   setShowVideo(true);
  //   scanner.start();
  // }, [showQr]);

  // useEffect(() => {
  //   if (qrCode && qrScanner) {
  //     qrScanner.stop();
  //     //setShowVideo(false);
  //     setShowEnterButton(true);
  //   }
  // }, [qrCode]);

  // useEffect(() => {
  //   return () => {
  //     if (qrScanner) {
  //       qrScanner.destroy();
  //       setQrScanner(null);
  //     }
  //   };
  // }, [qrScanner]);

  // useEffect(() => {
  //   if (!qrCode) return;
  //   const searchTerm = "code=";
  //   const position = qrCode.search(searchTerm);
  //   const result = qrCode.substring(position + searchTerm.length);
  //   reactLocalStorage.set("code", result);
  // }, [qrCode]);

  useEffect(() => {
    if (localStorage.getItem("time_expired") === "true") {
      setIsTimeExpired(true);
    }

    setShowContent(true);
    localStorage.setItem("time_expired", false);
  }, []);

  const selectLanguage = async (item) => {
    setLanguage(item);
  };
  const fetchAll = async () => {
    try {
      const questionnaireCompleted = reactLocalStorage.get(
        "questionnaireCompleted",
        true
      );
      setLoading(true);

      const searchParams = new URLSearchParams(window.location.search);
      const param = searchParams.get("code");

      const code = [num1, num2, num3, num4].join("-");
      const {
        data: {collections, languages, plantas, salas, submenu, cuestionario},
      } = await getAllData(code);
      reactLocalStorage.set("code", code);
      setLanguages(languages);
      setDoQuestionnaire(questionnaireCompleted);

      setCollections(collections);
      setPlantas(plantas);
      setSalas(salas);
      setSubMenus(submenu);
      setQuestionnaire(cuestionario);
      history.push("/languages");
      //history.push("/start");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const disabled = () => {
    if (num1 === null || isNaN(num1) || num1 === "") return false;
    if (num2 === null || isNaN(num2) || num2 === "") return false;
    if (num3 === null || isNaN(num3) || num3 === "") return false;
    if (num4 === null || isNaN(num4) || num4 === "") return false;
    return true;
  };

  return (
    <React.Fragment>
      {/* <Header /> */}
      {showContent && (
        <>
          {" "}
          <ContainerW className="d-flex flex-column align-items-center">
            {!language ? (
              <>
                {languages.length > 0 &&
                  languages.map((item) => {
                    return (
                      <Row key={`lang-${item.id}`} className="mt-4">
                        <Col className="d-flex justify-content-center">
                          <AppButton
                            onClick={async () => {
                              await selectLanguage(item);
                              i18n.changeLanguage(item.abreviatura);
                            }}
                          >
                            <span>{item.nombre}</span>&nbsp;
                          </AppButton>
                        </Col>
                      </Row>
                    );
                  })}
              </>
            ) : (
              <>
                <>
                  <RowW className="w-100 d-flex mb-4">
                    {" "}
                    {!isTimeExpired ? (
                      <img src={LockIcon} alt="" width="75" />
                    ) : (
                      <img src={ClockIcon} alt="" width="50" />
                    )}
                  </RowW>
                  <RowW className="w-100 d-flex ">
                    {!isTimeExpired ? (
                      <div>
                        <LineP>{t("invalid_token.line1")}</LineP>
                        <LineP>{t("invalid_token.line2")}</LineP>
                      </div>
                    ) : (
                      <div>
                        <LineP>{t("time_expired.line1")}</LineP>
                        <LineP>{t("time_expired.line2")}</LineP>
                      </div>
                    )}{" "}
                  </RowW>{" "}
                </>
              </>
            )}
          </ContainerW>
        </>
      )}
    </React.Fragment>
  );
};

export default checkData(Lock);
