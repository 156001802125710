import React, {useState, useMemo} from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import {BrowserRouter as Router} from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import {AppContext} from "./AppContext";

const Main = () => {
  const [language, setLanguage] = useState(null);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [doQuestionnaire, setDoQuestionnaire] = useState(true);
  const [filter, setFilter] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [collections, setCollections] = useState([]);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [salas, setSalas] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [subMenus, setSubMenus] = useState([]);
  const [codeExpires, setCodeExpires] = useState(0);
  const [codeTotalHours, setCodeTotalHours] = useState(0);
  const [codeTimeLeft, setCodeTimeLeft] = useState(0);
  const [isActivatedCode, setIsActivatedCode] = useState(false);
  const [isActiveSocket, setIsActiveSocket] = useState(false);
  const [isUserLocked, setIsUserLocked] = useState(false);
  const [activationDate, setActivationDate] = useState(null);
  const value = useMemo(
    () => ({
      languages,
      setLanguages,
      language,
      setLanguage,
      collections,
      setCollections,
      isContentLoaded,
      setIsContentLoaded,
      salas,
      setSalas,
      plantas,
      setPlantas,
      filter,
      setFilter,
      subMenus,
      setSubMenus,
      questionnaire,
      setQuestionnaire,
      doQuestionnaire,
      setDoQuestionnaire,
      codeExpires,
      setCodeExpires,
      codeTotalHours,
      setCodeTotalHours,
      codeTimeLeft,
      setCodeTimeLeft,
      isActivatedCode,
      setIsActivatedCode,
      isActiveSocket,
      setIsActiveSocket,
      isUserLocked,
      setIsUserLocked,
      activationDate,
      setActivationDate,
    }),
    [
      languages,
      setLanguages,
      language,
      setLanguage,
      collections,
      setCollections,
      isContentLoaded,
      setIsContentLoaded,
      salas,
      setSalas,
      plantas,
      setPlantas,
      filter,
      setFilter,
      subMenus,
      setSubMenus,
      questionnaire,
      setQuestionnaire,
      doQuestionnaire,
      setDoQuestionnaire,
      codeExpires,
      setCodeExpires,
      codeTotalHours,
      setCodeTotalHours,
      codeTimeLeft,
      setCodeTimeLeft,
      isActiveSocket,
      setIsActiveSocket,
      isActivatedCode,
      setIsActivatedCode,
      isUserLocked,
      setIsUserLocked,
      activationDate,
      setActivationDate,
    ]
  );

  const trackingId = "UA-169247461-1"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);

  window.addEventListener("appinstalled", function (event) {
    ReactGA.event({
      category: "Application",
      action: "installed",
    });
  });

  return (
    <AppContext.Provider value={value}>
      <Router>
        <App />
      </Router>
    </AppContext.Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
