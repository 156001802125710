import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import TrackVisibility from "react-on-screen";
import { Card } from "react-bootstrap";

import NOTFOUND from "./../../assets/image.png";

const Image = styled(Card.Img)`
  .card-img-top {
    width: 100%;
    height: auto !important;
    max-height: 650px !important;
  }

  ${(props) =>
    props.hide &&
    css`
      display: none;
    `};
`;

const ComponentToTrack = ({ height, hide, url }) => {
  
  const style = (loading, height) => {
    return {
      transition: "0.5s filter linear",
      filter: `${loading ? "blur(50px)" : ""}`,
    };
  };
  return (
    <Image
      hide={hide}
      style={style(!url, height)}
      variant="top"
      src={url || NOTFOUND}
    />
  );
};

const ImageLoaderCmp = ({ id, height, hide, url }) => {
  return (
    <TrackVisibility>
      <ComponentToTrack id={id} height={height} hide={hide} url={url} />
    </TrackVisibility>
  );
};

export default React.memo(ImageLoaderCmp, (prevProps, props) => {
  return prevProps.id === props.id;
});
