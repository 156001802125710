import React from "react";
import styled from "styled-components";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
const modalContainer = {
  position: "fixed",
  zIndex: 9999,
  paddingTop: "100px",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  overflow: "auto",
  backgroundColor: "rgba(0,0,0,0.6)",
};
const modalStyle = {
  backgroundColor: "#fefefe",
  padding: "20px",
  border: "1px solid #888",
  width: "90%",
  position: "absolute",
  marginLeft: "auto",
  marginRight: "auto",
  right: 0,
  left: 0,
  top: "50%",
  transform: "translateY(-50%)",
};
const PTitle = styled.p`
  font-family: Montserrat-Regular;
  font-size: 22px;
  font-weight: 600;
`;
const LineP = styled.p`
  font-family: Montserrat-Regular;
  font-size: 16px;
  margin-bottom: 5px;
`;
const AppButton = styled(Button)`
  color: #000000;
  background-color: transparent;
  font-family: Montserrat-Regular;
  letter-spacing: 1px;
  font-size: 16px;
  width: 100%;
  padding: 10px 0px;
  border-radius: 20px;
  border: 1px solid #d58a95;
  text-transform: uppercase;
  &:hover {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
`;

const ConfirmAccessModal = ({showModal, closeModal, start}) => {
  const {t} = useTranslation();
  return (
    <div
      style={modalContainer}
      className={`${showModal ? "d-block" : "d-none"}`}
    >
      {" "}
      <div style={modalStyle} className="d-flex flex-column">
        {" "}
        <div>
          <span
            className="close"
            onClick={closeModal}
            style={{fontSize: "2.5rem"}}
          >
            &times;
          </span>
        </div>
        <PTitle> {t("confirm_modal.title")}</PTitle>
        <LineP>{t("confirm_modal.line1")}</LineP>
        <LineP>{t("confirm_modal.line2")}</LineP>
        <div className="d-flex mt-5">
          <div className="w-50 pr-2">
            {" "}
            <AppButton onClick={closeModal}>{t("buttons.cancel")}</AppButton>
          </div>
          <div className="w-50 pl-2">
            {" "}
            <AppButton onClick={start}>{t("buttons.access")}</AppButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAccessModal;
