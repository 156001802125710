import React, {useState, useContext, useEffect} from "react";
import {Container, Row, Col, Button, Spinner} from "react-bootstrap";
import styled from "styled-components";
import {reactLocalStorage} from "reactjs-localstorage";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Header from "./../../components/Header";
import TimerIcon from "../../assets/svg/timer.svg";
import AudioIconSvg from "../../assets/svg/audio.svg";
import Timer from "./Timer";
// import initiateSocket from "../../utils/Socket";
import {initiateSocket} from "../../utils/Socket";
import {AppContext} from "./../../AppContext";

import {getAllData, getFile, getImage} from "./../../services";
import i18next from "i18next";
import ConfirmAccessModal from "../../components/Modals/ConfirmAccessModal";
import {checkData} from "../../hoc";
import ClockSvg from "../../components/SVGS/ClockSvg";
import {activateCode} from "../../utils/Api";
const pLimit = require("p-limit");
const limit = pLimit(5);

const AppButton = styled(Button)`
  color: #000000;
  background-color: transparent;
  font-family: Montserrat-Regular;
  letter-spacing: 1px;
  font-size: 16px;
  width: 300px;
  padding: 10px 0px;
  border-radius: 20px;
  border: 1px solid #d58a95;
  text-transform: uppercase;
  &:hover {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
`;

const ContainerW = styled(Container)`
  min-height: 100vh;
  padding-top: 120px;
  //justify-content: center;
  //   margin-top: 130px;
  //   & {
  //     @media screen and (max-width: 400px) {
  //       margin-top: 100px;
  //     }
  //   }
`;

const CodeNumber = styled.input`
  color: #000000;
  width: 40px;
  border: none;
  border-bottom: 2px solid black;
  background-color: transparent;
  height: 55px;
  font-size: 50px;
  text-align: center;
  padding: 0;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
  font-family: Arial; //or Helvetica, or whatever
  /* Firefox */
  &:input[type="number"] {
    -moz-appearance: textfield;
  }
  &:focus {
    outline: none;
  }
  line-height: 0;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: blue;
  }
`;

const Div = styled.div``;
const RowW = styled(Row)`
  margin: 0;
  padding: 0 20px;
`;

const RowButton = styled(Row)`
  position: absolute;
  bottom: 0;
  flex-direction: column;
`;
const P = styled.p`
  font-family: Montserrat-Regular;
  font-size: 22px;
  font-weight: 600;
`;

const LineP = styled.p`
  font-family: Montserrat-Regular;
  font-size: 16px;
`;

const PBottom = styled.p`
  font-weight: 600;
  margin: 0;
  font-size: 16px;
`;

const StyledTimer = styled.p`
  font-size: 30px;
`;

const AudioIconImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function convertMsToTime(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  //hours = hours % 24;
  if (hours < 1 && minutes >= 1)
    return `${padTo2Digits(minutes)}m${padTo2Digits(seconds)}`;
  else if (hours < 1 && minutes < 1) return `${padTo2Digits(seconds)}s`;
  return `${hours}h${padTo2Digits(minutes)}`;
}

const Start = () => {
  let history = useHistory();
  const [load, setLoad] = useState(true);
  const {pathname} = useLocation();
  const {t, i18n} = useTranslation();
  const [showLanguages, setShowLanguages] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const {
    setIsActiveSocket,
    codeTimeLeft,
    codeTotalHours,
    codeExpires,
    isActivatedCode,
    language,
    languages,
    setLanguage,
    isContentLoaded,
    isActiveSocket,
    setActivationDate,
  } = useContext(AppContext);

  // useEffect(() => {
  //   if (isActivatedCode && language && load) startConnection();
  // }, [isActivatedCode, language, load]);

  const startConnection = async () => {
    try {
      if (!isActivatedCode) {
        const data = await activateCode(reactLocalStorage.get("code", false));
        setActivationDate(data.activated);
      }

      setIsActiveSocket(true);
      history.push("/");
    } catch (err) {
      console.log(err);
    }
    // try {
    //   await initiateSocket(reactLocalStorage.get("code", false));
    //   setIsActiveSocket(true);
    //   history.push("/");
    // } catch (err) {
    //   console.log(err);
    // }
  };
  const selectLanguage = async (item) => {
    setLanguage(item);
  };
  return (
    <React.Fragment>
      {/* <Header /> */}
      <ConfirmAccessModal
        showModal={showConfirmModal}
        closeModal={() => {
          setShowConfirmModal(false);
        }}
        start={startConnection}
      />
      <ContainerW className="d-flex flex-column align-items-center">
        {!language ? (
          <>
            {languages.length > 0 &&
              languages.map((item) => {
                return (
                  <Row key={`lang-${item.id}`} className="mt-4">
                    <Col className="d-flex justify-content-center">
                      <AppButton
                        onClick={async () => {
                          setLoad(false);
                          await selectLanguage(item);
                          //reactLocalStorage.set("language", item.abreviatura);
                          i18n.changeLanguage(item.abreviatura).then(() => {
                            setShowLanguages(false);
                            if (isActivatedCode) startConnection();
                          });
                        }}
                      >
                        <span>{item.nombre}</span>&nbsp;
                      </AppButton>
                    </Col>
                  </Row>
                );
              })}
          </>
        ) : (
          <>
            {!isActivatedCode && (
              <>
                {" "}
                <RowW className="w-100 d-flex">
                  <P>{t("start_page.title")}</P>
                </RowW>
                <RowW className="w-100 d-flex ">
                  <LineP>{t("start_page.line1")}</LineP>
                  <LineP>{t("start_page.line2")}</LineP>
                </RowW>
                <RowButton className="w-100 d-flex justify-content-center">
                  {" "}
                  <Col className="d-flex align-items-center justify-content-center p">
                    {" "}
                    <img src={TimerIcon} alt="" width="20" className="mr-2" />
                    <PBottom className="font-weight-bold">
                      {t("start_page.bottom_text")}
                    </PBottom>
                  </Col>
                  <Col className="d-flex justify-content-center">
                    <AppButton
                      style={{marginTop: 20, marginBottom: 20}}
                      // onClick={startConnection}
                      onClick={() => {
                        setShowConfirmModal(true);
                      }}
                    >
                      <React.Fragment>{t("buttons.activate")}</React.Fragment>
                    </AppButton>
                  </Col>
                </RowButton>
              </>
            )}
            {/* <RowW className="w-100 d-flex m-4">
              {" "}
              <div className="position-relative">
                {isActivatedCode ? (
                  <>
                    <ClockSvg
                      codeTimeLeft={codeTimeLeft}
                      isHeaderIcon={false}
                    ></ClockSvg>{" "}
                    <AudioIconImg src={AudioIconSvg} alt="" width="40" />
                  </>
                ) : (
                  <img src={AudioIconSvg} alt="" width="80" />
                )}
              </div>
            </RowW> */}
          </>
        )}
      </ContainerW>
    </React.Fragment>
  );
};

export default checkData(Start);
