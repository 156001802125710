import React, {useContext, useState} from "react";
import {Container, Row, Col, Button, ProgressBar} from "react-bootstrap";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ClockLoader from "react-spinners/ClockLoader";
import ReactGA from "react-ga";
import map from "async/map";
import waterfall from "async/waterfall";
import {AppContext} from "./../../AppContext";
import Header from "./../../components/Header";
import {getFile, getImage, getFileTest, getImageTest} from "./../../services";
import HOMEIMG from "./../../assets/home.jpg";
import LOGO from "./../../assets/logo1.svg";
import {checkData} from "../../hoc";

const pLimit = require("p-limit");
const limit = pLimit(5);
const AppButton = styled(Button)`
  color: #000000;
  background-color: transparent;
  font-family: Montserrat-Regular;
  letter-spacing: 1px;
  font-size: 16px;
  width: 300px;
  padding: 10px 0px;
  border-radius: 20px;
  border: 1px solid #d58a95;
  &:hover {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
`;

const ContainerWrapper = styled(Container)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Div = styled.div`
  margin-top: 130px;
  margin-bottom: 60px;
  h1 {
    font-family: Montserrat-Medium;
    font-size: 22px;
    text-align: center;
  }
  & {
    @media screen and (max-width: 600px) {
      margin-top: 110px;
    }
  }
`;

const RowW = styled(Row)`
  padding: 0;
  margin: 0;
  background-color: #f2f1ee !important;
`;
const IMG = styled.img`
  width: 160px;
  height: 70px;
`;

const Languages = () => {
  let history = useHistory();
  const {t, i18n} = useTranslation();
  const {
    languages,
    setLanguage,
    collections,
    setCollections,
    setIsContentLoaded,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);

  const selectLanguage = async (item) => {
    setLanguage(item);
    runGA(item);
    await loadData(item);
  };
  const cleanObra = (obra) => {
    delete obra.audioUrl;
    delete obra.videoUrl;
    delete obra.ubicacionUrl;
    delete obra.imageUrl;
  };
  const processObra = async (obra, lang, collection, contentType, callback) => {
    try {
      let response = {};
      if (contentType === "audio" || contentType === "video") {
        response = await getFile(obra[contentType][lang.abreviatura]);
      } else {
        if (contentType === "image") {
          response = await getImage(obra.imagen_id);
        } else {
          response = await getImage(obra[contentType][lang.abreviatura]);
        }
      }
      const url = URL.createObjectURL(response.data);
      obra[`${contentType}Url`] = url;
      return callback();
    } catch (error) {
      console.log("----------------------------------");
      console.log(collection.nombre[lang.abreviatura]);
      console.log(obra.titulo[lang.abreviatura]);
      console.log("----------------------------------");
      return callback();
    }
  };
  const loadData = async (lang) => {
    try {
      let count = 0;
      let total = 0;

      for (let i = 0; i < collections.length; i++) {
        if (collections[i].activa) {
          for (let j = 0; j < collections[i].obras.length; j++) {
            if (collections[i].obras[j]) {
              cleanObra(collections[i].obras[j]);
              if (collections[i].obras[j].audio[lang.abreviatura]) {
                total++;
              }
              if (collections[i].obras[j].imagen_id) {
                total++;
              }
              if (collections[i].obras[j].ubicacion[lang.abreviatura]) {
                total++;
              }
              if (collections[i].obras[j].video[lang.abreviatura]) {
                total++;
              }
            } else {
              console.log(collections[i]);
            }
          }
        }
      }
      setLoading(true);
      const callback = () => {
        count++;
        setPercent(parseInt((count * 100) / total));
      };
      let promises = [];
      for (let i = 0; i < collections.length; i++) {
        const collection = collections[i];
        if (collection.activa) {
          const list = collection.obras.map((obra) => {
            let localList = [];
            if (obra.video && obra.video[lang.abreviatura]) {
              const prom = limit(() =>
                processObra(obra, lang, collection, "video", callback)
              );
              localList.push(prom);
            }
            if (obra.audio && obra.audio[lang.abreviatura]) {
              const prom = limit(() =>
                processObra(obra, lang, collection, "audio", callback)
              );
              localList.push(prom);
            }
            if (obra.ubicacion && obra.ubicacion[lang.abreviatura]) {
              const prom = limit(() =>
                processObra(obra, lang, collection, "ubicacion", callback)
              );
              localList.push(prom);
            }
            if (obra.imagen_id) {
              const prom = limit(() =>
                processObra(obra, lang, collection, "image", callback)
              );
              localList.push(prom);
            }
            return localList;
          });
          for (const l of list) {
            promises.push(...l);
          }
        }
      }
      await Promise.all(promises);
      setCollections(collections);
      //console.log('Languages set collection', collections);
      // setLoading(false);
      history.push("/");
    } catch (error) {
      console.log(error);
    } finally {
      setIsContentLoaded(true);
      setLoading(false);
    }
  };

  const runGA = (item) => {
    ReactGA.event({
      category: "Language",
      action: `Selected -${item.nombre}`,
      value: parseInt(item.id),
    });
  };

  return (
    <React.Fragment>
      {/* <Header /> */}
      {/* <Div>{<h1>Audioguía</h1>}</Div> */}

      {!loading && (
        <ContainerWrapper>
          {/* <Row>
            <Col>
              <img className="w-100" src={HOMEIMG} alt="" />
            </Col>
          </Row> */}
          {languages.length < 1 && (
            <Row>
              <Col className="d-flex justify-content-center">
                <ClockLoader size={100} color={"#D58A95"} loading={true} />
              </Col>
            </Row>
          )}
          {languages.length > 0 &&
            languages.map((item) => {
              return (
                <Row key={`lang-${item.id}`} className="mt-4">
                  <Col className="d-flex justify-content-center">
                    <AppButton
                      onClick={async () => {
                        //await selectLanguage(item);
                        //reactLocalStorage.set("language", item.abreviatura);
                        i18n.changeLanguage(item.abreviatura).then(() => {
                          //setShowLanguages(false);
                          selectLanguage(item);
                        });
                      }}
                    >
                      <span>{item.nombre}</span>&nbsp;
                    </AppButton>
                  </Col>
                </Row>
              );
            })}
        </ContainerWrapper>
      )}
      {loading && (
        <ContainerWrapper>
          {/* <RowW>
            <Col
              style={{marginTop: 120}}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <IMG src={LOGO} alt="" />
              </div>
            </Col>
          </RowW> */}
          <RowW>
            <Col className="d-flex justify-content-center align-items-center">
              <div>
                <ClockLoader size={100} color={"#D58A95"} loading={true} />
              </div>
            </Col>
          </RowW>
          <Row>
            <Col className="px-5 col mt-3">
              {" "}
              {t("loading_data")} <span>({`${percent}%`})</span>
              <ProgressBar now={percent} label={`${percent}%`} srOnly />
              {/* Cargando Datos ... <span>({`${percent}%`})</span>
              <ProgressBar now={percent} label={`${percent}%`} srOnly /> */}
            </Col>
          </Row>
        </ContainerWrapper>
      )}
    </React.Fragment>
  );
};

export default checkData(Languages);
