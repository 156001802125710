import React, {useContext} from "react";
import {useEffect, useState} from "react";
import styled from "styled-components";
import {AppContext} from "./../../AppContext";

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function convertMsToTime(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  //hours = hours % 24;
  if (hours < 1 && minutes >= 1)
    return `${padTo2Digits(minutes)}m${padTo2Digits(seconds)}`;
  else if (hours < 1 && minutes < 1) return `${padTo2Digits(seconds)}s`;
  return `${hours}h${padTo2Digits(minutes)}m${padTo2Digits(seconds)}s`;
}

const StyledTimer = styled.span`
  font-size: 18px;
`;

const Timer = () => {
  const [seconds, setSeconds] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);

  const {codeTimeLeft, isActiveSocket, activationDate, codeExpires} =
    useContext(AppContext);

  useEffect(() => {
    console.log(codeExpires);
    console.log(activationDate);
    console.log(codeExpires * 60 * 1000);
  }, []);

  useEffect(() => {
    if (!isActiveSocket) return;
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isActiveSocket]);

  // useEffect(() => {
  //   if (seconds === 0) {
  //   }
  //   setTimeLeft(initialTime - seconds * 1000);
  // }, [seconds, initialTime]);

  return (
    <StyledTimer>
      <span>{convertMsToTime(500)}</span>
    </StyledTimer>
  );
};
export default Timer;
