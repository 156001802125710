import React, {useEffect, useContext} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {reactLocalStorage} from "reactjs-localstorage";
import {validateCode} from "../utils/Api";

import {AppContext} from "./../AppContext";

export const checkData = (Component) => (props) => {
  let history = useHistory();
  const {pathname} = useLocation();
  const {language, collections, isActiveSocket, isUserLocked} =
    useContext(AppContext);
  useEffect(() => {
    if (isUserLocked) history.push("/lock");
    //console.log(pathname, isActiveSocket);
    // console.log("CUrrent", pathname);
    // console.log("Last", reactLocalStorage.get("last_path"));
    // console.log("HOC", pathname);
    // console.log(isActiveSocket);
    //if (isActiveSocket && pathname === "/start") history.push("/");
    // if (!isContentLoaded) {
    //   await fetchAll(false);
    // }
    //history.push("/start");
    // if (!language || !collections.length) {
    //   history.push("/languages");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Component {...props} />;
};
