import React, {useEffect, useContext, useState} from "react";
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Redirect,
  useParams,
} from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import ReactGA from "react-ga";
import {Row, Col} from "react-bootstrap";
import {reactLocalStorage} from "reactjs-localstorage";

import {AppContext} from "./AppContext";

import Drawer from "./components/Drawer";
import Home from "./pages/Home";
import Collection from "./pages/Collection";
import Artwork from "./pages/Artwork";
import Languages from "./pages/Languages";
import Lock from "./pages/Lock";
import Questionnaire from "./pages/Questionnaire";
import Maps from "./pages/Maps";
import Header from "./components/Header";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";
import "./styles/normalize.css";

import {login, getAllData} from "./services";
import {validateCode, verifyCode, getCode} from "./utils/Api";
import Start from "./pages/Start";
import {disconnectSocket} from "./utils/Socket";

function usePageViews() {
  let location = useLocation();
  let {id} = useParams();

  React.useEffect(() => {
    ReactGA.set({page: location.pathname}); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }, [location]);
}

const App = () => {
  const location = useLocation();

  let history = useHistory();
  usePageViews();
  const [loading, setLoading] = useState(true);
  const {
    setLanguages,
    setCollections,
    setSalas,
    setPlantas,
    setSubMenus,
    setQuestionnaire,
    setDoQuestionnaire,
    setCodeTimeLeft,
    setCodeExpires,
    setCodeTotalHours,
    setIsActivatedCode,
    isActiveSocket,
    setIsActiveSocket,
    isContentLoaded,
    setIsUserLocked,
    setActivationDate,
  } = useContext(AppContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (location.pathname !== "/maps") {
      main();
    }

    // document.addEventListener("visibilitychange", (event) => {
    //   if (document.visibilityState === "visible") {
    //     setLoading(true);
    //     init()
    //       .then(() => {
    //         history.push("/start");
    //         setIsUserLocked(false);
    //         setLoading(false);
    //       })
    //       .catch(() => {
    //         history.push("/lock");
    //         setLoading(false);
    //         setIsUserLocked(true);
    //       });
    //   } else {
    //     setIsActiveSocket(false);
    //     disconnectSocket();
    //     history.push("/");
    //   }
    // });
    // return () => {
    //   document.removeEventListener("visibilitychange", () => {});
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => console.log("CLEAN");
  }, []);

  const init = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const shortCode = searchParams.get("p");
    const param = searchParams.get("code");

    let code = "";
    if (shortCode) {
      const res = await getCode(shortCode);
      code = res.data.real_url;
    } else {
      code = param;
    }

    if (code) reactLocalStorage.set("code", code);
    const localCode = localStorage.getItem("code");

    const token = code ? code : localCode;
    try {
      const {
        data: {languages},
      } = await getAllData();
      setLanguages(languages);

      const codeData = await validateCode(token);
      // if (!isContentLoaded) {
      //   await fetchAll(false);
      // }

      setActivationDate(codeData.data.activated);
      setCodeTimeLeft(codeData.data.time_left);
      setCodeExpires(codeData.data.expires);
      setCodeTotalHours(codeData.data.total_hours);

      if (codeData.data.activated) setIsActivatedCode(true);
    } catch (err) {
      throw new Error();
    }
  };

  const main = async () => {
    setDoQuestionnaire();
    setLoading(true);
    await initialize();
    setLoading(false);
    // setInterval(async () => {
    //   await initialize(true);
    // }, 1000 * 60 * 5);
  };

  const initialize = async (onlySync = false) => {
    await doLogin();
    try {
      if (!onlySync) await init();
      await fetchAll(onlySync);
      history.push("/start");
      setIsUserLocked(false);
    } catch (err) {
      history.push("/lock");
      setIsUserLocked(true);
    }
  };

  const doLogin = async () => {
    try {
      const {
        data: {token, lock},
      } = await login();

      reactLocalStorage.set("token", token);
      reactLocalStorage.set("lock", lock);
      ReactGA.set({
        userId: token,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAll = async (onlySync = false) => {
    try {
      const code = reactLocalStorage.get("code", null);
      const lock = reactLocalStorage.get("lock", false);
      const questionnaireCompleted = reactLocalStorage.get(
        "questionnaireCompleted",
        true
      );
      if (eval(lock)) {
        const {
          data: {collections, languages, plantas, salas, submenu, cuestionario},
        } = await getAllData();
        if (onlySync) {
          //console.log("Only sync skip setcollections");
          return;
        }
        //console.log("App code ok set collections");
        setLanguages(languages);
        setDoQuestionnaire(questionnaireCompleted);
        setCollections(collections);
        setPlantas(plantas);
        setSalas(salas);
        setSubMenus(submenu);
        setQuestionnaire(cuestionario);
      } else {
        const {
          data: {collections, languages, plantas, salas, submenu, cuestionario},
        } = await getAllData();
        if (onlySync) {
          //console.log("Only sync in bad code skip setcollections");
          return;
        }
        //console.log("App bad code set collections");
        setLanguages(languages);
        setDoQuestionnaire(questionnaireCompleted);
        setCollections(collections);
        setPlantas(plantas);
        setSalas(salas);
        setSubMenus(submenu);
        setQuestionnaire(cuestionario);
      }
    } catch (error) {
      //console.log("LOCK");
      reactLocalStorage.remove("code");
      setIsUserLocked(true);
      history.push("/lock");
    }
  };

  usePageViews();
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/maps">
          <Maps />
        </Route>
      </Switch>
      <Drawer />
      {location.pathname !== "/maps" && (
        <React.Fragment>
          {loading && (
            <Row style={{marginTop: 260}}>
              <Col className="d-flex justify-content-center">
                <ClockLoader size={100} color={"#D58A95"} loading={true} />
              </Col>
            </Row>
          )}
          {!loading && (
            <div id="page-wrap">
              <Header />
              <main>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/maps">
                    <Maps />
                  </Route>
                  <Route exact path="/start">
                    <Start />
                  </Route>
                  <Route exact path="/lock">
                    <Lock />
                  </Route>
                  <Route path="/languages">
                    <Languages />
                  </Route>

                  <Route exact path="/collection/:collectionId">
                    <Collection />
                  </Route>
                  <Route
                    exact
                    path="/collection/:collectionId/subCollection/:subCollectionId"
                  >
                    <Collection />
                  </Route>
                  <Route
                    exact
                    path="/collection/:collectionId/artwork/:artworkId"
                  >
                    <Artwork />
                  </Route>
                  <Route exact path="/questionnaire">
                    <Questionnaire />
                  </Route>

                  <Redirect to="/" />
                </Switch>
              </main>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default App;
